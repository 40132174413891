html, body {
  height: 100%;
  color: #848282;
}

#root {
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-Direction: column;
  align-items: center;
  justify-content: center;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.english {
  font-size: 48px;
  color: #848282;
  text-align: center;
}

.english a {
  text-decoration: inherit;
  color: inherit;
}

.input-field {
  font-size: 40px;
  text-align: center;
  font-weight: 200;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 15px;
  border-radius: 15px;
  border-width: 1px;
  width: 75vw;
  outline: none;
  box-sizing: border-box;
  border-color: #8b8b8b;
  color: #8b8b8b;
  transition: backgroundColor .1s;
}

.input-field:focus {
  border: 1px solid #444;
}


.button-row {
  width: 75vw;
  display: flex;
  justify-content: flex-start;
  height: 50px;
}

.button {
  height: 30px;
  background-color: white;
  border: 1px solid #cbc7c7;
  border-radius: 5px;
  color: #949494;
  outline: none;
}

.ml {
  margin-left: 10px;
}

.spacer {
  flex-grow: 1;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator-row {
  width: 75vw;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
}

.solution-indicator {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0px 5px;
}

.info {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.info p {
  margin: 0;
  margin-bottom: 10px;
  color: #848282;
}

.pointer {
  cursor: pointer
}

.settings-button {
  position: absolute;
  top: 10px;
  left: 20px;
  color: #848282;
  cursor: pointer;
}

.settings {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  transform: translate(-250px, 0px);
  padding: 20px;
  transition: transform .2s;
  box-shadow: -8px 0px 10px #e5e5e5 inset;
  box-sizing: border-box;
  overflow: auto;
}

.settings.open {
  width: 250px;
  background-color: #ececec;
  transform: translate(0px, 0px);
}

.disabled {
  filter: opacity(.5);
  pointer-events: none;
}

.meta {
  width: 75vw;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 5px;

}

.meta .left-side {
  justify-items: flex-start;
}

.meta .right-side {
  justify-items: flex-end;
}

.congrats-title {
  font-size: 55px;
  font-weight: 400;
  margin-bottom: 0px;
}

.congrats-description {
  font-size: 26px;
}

@media only screen and (max-width: 600px) {
  .english {
    font-size: 30px;
    width: 80vw;
    margin-bottom: 50px;
  }

  .info {
    font-size: 20px;
  }

  .meta {
    flex-direction: column;
  }

  .meta * {
    margin-bottom: 10px;
    text-align: center;
  }

  .button-row {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
  }

  .spacer {
    display: none;
  }

  .button {
    margin: 10px 10px 0px 0px 
  }
}